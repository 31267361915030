"use client";

import { Button } from "@/Common/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/Common/ui/sheet";
import { Menu } from "lucide-react";
import { useState } from "react";

export function NavSheetView() {
  const [isOpen, setIsOpen] = useState(false);

  const closeSheet = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setIsOpen(false);
    const href = event.currentTarget.getAttribute('href');
    if (href) {
      setTimeout(() => {
        const element = document.querySelector(href);
        element?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild className="md:hidden">
        <Button variant="outline" size="icon">
          <Menu className="size-6" />
          <span className="sr-only">Toggle menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="top">
        <nav className="flex flex-col space-y-4">
          <a
            href="#organizations"
            className="text-foreground hover:text-primary"
            onClick={closeSheet}
          >
            Organizations
          </a>
          <a
            href="#services"
            className="text-foreground hover:text-primary"
            onClick={closeSheet}
          >
            Services
          </a>
          <a
            href="#tech-stack"
            className="text-foreground hover:text-primary"
            onClick={closeSheet}
          >
            Tech Stack
          </a>
          <Button variant="outline" asChild>
            <a href="#contact" onClick={closeSheet}>Get in touch</a>
          </Button>
        </nav>
      </SheetContent>
    </Sheet>
  );
}
